/* FONT DROUOT */
@font-face {font-family: 'LisztFY-Rg';src: url('../fonts/3632A9_0_0.eot');src: url('../fonts/3632A9_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/3632A9_0_0.woff2') format('woff2'),url('../fonts/3632A9_0_0.woff') format('woff'),url('../fonts/3632A9_0_0.ttf') format('truetype'); font-weight: 400;}
@font-face {font-family: 'LisztFY-Bd';src: url('../fonts/3632A9_1_0.eot');src: url('../fonts/3632A9_1_0.eot?#iefix') format('embedded-opentype'),url('../fonts/3632A9_1_0.woff2') format('woff2'),url('../fonts/3632A9_1_0.woff') format('woff'),url('../fonts/3632A9_1_0.ttf') format('truetype'); font-weight: 700;}

/* FONT MONITEUR */

@font-face {
    font-family: 'Open Sans'; font-style: normal; font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/***********************************************
*	GUI FONT *
***********************************************/
body, label, div, select, input {font-family: 'LisztFY-Rg', sans-serif;cursor:default; font-weight: 400;}
b, strong, .bold, .font-bold, .font-semibold, .font-weight-bold { font-family: 'LisztFY-Bd',sans-serif; font-weight: 700;}
* { box-sizing: border-box; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }
body { font-family: 'LisztFY-Rg'; font-weight: 400!important; font-size: 14px; line-height: 1.42857; color: #2b2b2b; background-color: #fff;}
h1 { font-family: 'LisztFY-Rg'; font-size: 29px; line-height: normal; margin: 25px 0!important; color: #2b2b2b; }
h2 { font-family: 'LisztFY-Rg'; font-size: 24px; line-height: normal; margin: 0 0 20px; color: #2b2b2b; }
h3 { font-family: 'LisztFY-Rg'; font-size: 20px; line-height: normal; margin: 20px 0 30px; color: #2b2b2b; }
h4, h4 a { font-family: 'LisztFY-Rg'; }
p { font-family: 'LisztFY-Rg'; font-size: 14px; line-height: normal; color: #2b2b2b; margin:0 0 10px }
a { font-family: 'LisztFY-Rg'; line-height: normal; color:#2b2b2b; }
a:hover, a:active, a:focus { color: #e4003a; text-decoration: none; }

/* ---    MONITEUR--------------*/
.moniteur * { font-family: 'Open Sans', sans-serif!important; }
.moniteur a { color: #084e6e!important;}
.moniteur .menu-sale a:hover, .drouot .menu-sale a:hover { color: #fff!important;}
.moniteur .bg-black-d:hover { background-color: #084e6e }

.btn { display: inline-block; vertical-align: middle; text-decoration: none; border-radius: 2px; }
.btn.ghost, .btn.ghost-icon { height: 38px; padding: 0 20px; border: 1px solid #2b2b2b; font-family: 'LisztFY-Bd'!important; font-size: 13px; line-height: 38px; color: #2b2b2b; background-color: #fff; transition: background-color .2s ease, color .2s ease; }
.btn.ghost:hover, .btn.ghost-icon:hover, .btn.ghost:active, .btn.ghost-icon:active, .btn.ghost:focus, .btn.ghost-icon:focus { transition: background-color 0.5s ease; background-color: #2b2b2b !important ; color: #fff!important; }
.btn.ghost.inverse { color: #fff!important; height: 38px; width: auto; padding: 0 20px; border: 1px solid #2b2b2b; font-family: 'LisztFY-Bd'; font-size: 13px; line-height: 38px;background-color: #2b2b2b; color: #fff; transition: background-color .2s ease, color .2s ease; }
.btn.ghost.inverse:hover, .btn.ghost.inverse:active, .btn.ghost.inverse:focus { background-color: #fff !important; color: #2b2b2b!important; border: 1px solid #2b2b2b;}
.font-red-d {color:#e4003a }
.shadow-lg {box-shadow:0 1rem 3rem rgba(0,0,0,.175)!important}
.bg-black-d:hover { background-color: #2b2b2b}
.border-d:hover { border-color: #2b2b2b}

/* ---    CODE RACCOURCI --------------*/
.clear {clear: both}
.back-grey { background-color: #f5f5f5}

/*error - success*/
.error { border-left: 4px solid #E7505A; padding: 10px 5px; margin-bottom: 25px; min-width: 300px}
.success { border-left: 4px solid #009b71; padding: 10px 5px; margin-bottom: 25px; min-width: 300px}

/* LOGO */
svg {max-width: 100%;height: auto;}
.logo-drouot, .logo-moniteur { opacity: 1; background-repeat: no-repeat;  height: 60px; background-size: contain;}
.logo-drouot { background-image:url(../img/logo-drouot-com.svg) !important; }
.logo-moniteur { background-image:url(../img/moniteur_live_bleu.svg) !important; }

@media only screen and (max-width: 1021px) {
    .logo-drouot, .logo-moniteur{ width: 280px;}
}

@media only screen and (max-width: 640px) {
    .logo-drouot, .logo-moniteur{ width: 200px; margin-bottom: 20px;}
}


/* FOOTER */
.footer-drouot {color:#333 !important; font-size: 13px; }
.footer-moniteur { color: #084e6e !important; }
.logo-drouot_footer { background-image:url(../img/logo-drouot.svg) !important;  background-size: 110px; height: 20px; width:150px; background-repeat: no-repeat;  }
.logo-moniteur_footer { background-image:url(../img/moniteur_live_bleu.svg) !important;  background-size:180px; height: 40px; background-repeat: no-repeat;margin-left: -10px  }

.logo-drouot_footer_noir { background-image:url(../img/logo-drouot-digital-noir.svg) !important;  background-size: 110px; height: 20px; width:150px; background-repeat: no-repeat;  }
.logo-moniteur_footer_noir { background-image:url(../img/moniteur_live_bleu.svg) !important;  background-size:180px; height: 40px; background-repeat: no-repeat; margin-left: -10px }

@media only screen and (max-width: 1021px) {
.footer .no-more { display: none;}
}

/* Best title*/
.best-title { font-family: 'LisztFY-Bd',sans-serif !important; font-weight: 700; font-size: 17px; border-bottom: 1px solid #2b2b2b; padding-bottom: 7px; float: left; margin-top: 15px; letter-spacing: inherit; text-transform: uppercase}
a span.best-title:hover { color: #E4003A!important; border-bottom: 1px solid #E4003A; }
.title-divider span {background: #dddddd none repeat scroll 0 0; display: inline-block; height: 1px; width: 100%; margin-bottom: 25px; float: left;}

/* Menu Compte */
.dropdown-menu  { position: inherit; z-index: 99999;}
.dropdown:hover .dropdown-menu {  opacity:1; transform: translate(0) scale(1); visibility: visible;}
.menu-category { margin-top: -18px;}
.menu-category, .menu-sale { font-size: 13px}
@media only screen and (max-width: 1021px) {
    .menu-category { display: none;}
    .menu-sale { width: 100%; font-size: 12px}
}
@media only screen and (max-width: 640px) {
    .menu-sale, .menu-desktop { display: none;}
}

/* Custom Input form*/
.MuiFormLabel-root { font-family: 'LisztFY-Rg', sans-serif!important;  font-weight: 400!important;  font-size: .75rem!important; line-height:1rem!important}
.MuiFormControl-root, .MuiInputBase-input { font-family: 'LisztFY-Rg', sans-serif!important;  font-weight: 400!important; width:100%!important ; font-size: .75rem!important; line-height:1rem!important ;background-color: #E8F0FE!important }
/*E5E7EB*/
.MuiStepContent-root { padding-left: 30px!important;}
.MuiStepper-vertical .MuiStepLabel-iconContainer { padding-right: 18px!important;}
.MuiNativeSelect-select.MuiNativeSelect-select, [class*=makeStyles-container-] .MuiInputBase-input { padding: 6px 12px 7px!important}
.react-tel-input .form-control { width: 100%!important}
.modal-phone { max-width: 500px; margin-top: 20px}
.step-window .modal-phone { max-width: inherit; margin-top:0; box-shadow:none!important; padding: 10px; }
.step-window .modal-phone .best-title { display: none; }
.step-window .modal-phone .title-divider { display: none; }
.spe-coo .MuiInputLabel-formControl {top: 5px!important;left: 10px!important;}
.spe-coo .MuiInputBase-input { padding-left: 10px!important}

/*phone input exported variables*/
:root {
    --PhoneInputCountrySelectArrow-color: "black" !important;
    --PhoneInputCountrySelectArrow-opacity: 1!important;
    --PhoneInputCountrySelectArrow-width: 0.4em!important;
    --PhoneInputCountryFlag-height: 1.5em!important;
}

[class*=makeStyles-formControl-]  { margin: 0!important}
[class*=makeStyles-formControl-] .MuiInputLabel-formControl {  top: 5px!important;  left: 12px!important;}
[class*=makeStyles-container-] .MuiInputLabel-formControl {  top: 5px!important;  left: 12px!important;}
.MuiInputLabel-outlined.MuiInputLabel-shrink {    transform: translate(14px, 8px) scale(0.75)!important;}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {   padding: 15px 9px 0!important;}
.PrivateNotchedOutline-legendLabelled-8 > span, .PrivateNotchedOutline-legendNotched-4 > span { display: none!important}
